import { useContext, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { ApuracaoContext } from 'contexts/ApuraçãoContext';
import { useModal } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import EditModal from './Modals/EditModal';
import moment from 'moment';
import styles from './styles';

const Apuração = () => {
  const { user } = useContext(AuthContext);
  const defaultValues = {
    filial_id: user?.filial_id,
    tributo_id: null,
    uf_id: null,
    dt: moment().format('MM/YYYY'),
    resp_nome: '',
    resp_qualificacao: '',
    resp_docto: '',
    codrec: '',
    codrecinter: '',
    deb_outros: '',
    deb_estorno: '',
    cred_outros: '',
    saldo_credor_anterior: '',
    deducoes: '',
  };
  const [apuracoes, setApuracoes] = useState([]);
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { getLoading, postLoading, getApuracoes, postApuracao } =
    useContext(ApuracaoContext);
  const { control, handleSubmit, setValue, watch } = useForm({ defaultValues });
  const debito = apuracoes?.find((f) => f?.operacao === 'SAIDA');
  const credito = apuracoes?.find((f) => f?.operacao === 'ENTRADA');
  const total_debitos =
    (debito?.imposto || 0) +
    (watch('deb_outros') || 0) +
    (debito?.estornocredito || 0);
  const total_creditos =
    (credito?.imposto || 0) +
    (watch('cred_outros') || 0) +
    (watch('deb_estorno') || 0) +
    (watch('saldo_credor_anterior') || 0);
  const total_devedor = total_debitos - total_creditos;
  const total_credor = total_creditos - total_debitos;
  const debitos = [
    {
      key: '1',
      label: 'Por saídas / Prestações com débito do imposto',
      value: debito?.imposto,
    },
    {
      key: '2',
      label: 'Outros débitos',
      value: watch('deb_outros'),
      editable: 'deb_outros',
    },
    {
      key: '3',
      label: 'Estornos de créditos',
      value: debito?.estornocredito,
    },
    {
      key: '4',
      label: 'Total',
      value: total_debitos,
      total: true,
    },
  ];
  const creditos = [
    {
      key: '1',
      label: 'Por entradas / Aquisições com crédito do imposto',
      value: credito?.imposto,
    },
    {
      key: '2',
      label: 'Outros créditos',
      value: watch('cred_outros'),
      editable: 'cred_outros',
    },
    {
      key: '3',
      label: 'Estornos de débitos',
      value: watch('deb_estorno'),
      editable: 'deb_estorno',
    },
    {
      key: '4',
      label: 'Saldo credor do período anterior',
      value: watch('saldo_credor_anterior'),
      editable: 'saldo_credor_anterior',
    },
    {
      key: '5',
      label: 'Total',
      value: total_creditos,
      total: true,
    },
  ];
  const balanco = [
    {
      key: '1',
      label: 'Saldo devedor',
      value: total_devedor,
    },
    {
      key: '2',
      label: 'Deduções',
      value: watch('deducoes'),
      editable: 'deducoes',
    },
    {
      key: '3',
      label: 'Imposto a recolher',
      value: total_devedor - (watch('deducoes') || 0),
    },
    {
      key: '4',
      label: 'Imposto fixado a maior por período',
      value: (watch('deducoes') || 0) - total_devedor,
    },
    {
      key: '5',
      label: 'Saldo credor',
      value: total_credor,
    },
  ];

  const renderData = (data = []) => {
    const finder = data?.find((f) => Boolean(f));
    setValue('resp_docto', finder?.resp_docto || '');
    setValue('resp_nome', finder?.resp_nome || '');
    setValue('resp_qualificacao', finder?.resp_qualificacao || '');
    setValue('codrec', finder?.codrec || '');
    setValue('codrecinter', finder?.codrecinter || '');
    setValue('deb_outros', finder?.deb_outros || '');
    setValue('deb_estorno', finder?.deb_estorno || '');
    setValue('cred_outros', finder?.cred_outros || '');
    setValue('saldo_credor_anterior', finder?.saldo_credor_anterior || '');
    setValue('deducoes', finder?.deducoes || '');
    setApuracoes(data);
  };

  const loadData = ({ filial_id, tributo_id, uf_id, dt }) => {
    const params = { filial_id, tributo_id, uf_id, dt };
    getApuracoes({ params, cb: renderData });
  };

  const onsubmit = (data) => postApuracao({ data });

  const Line = ({ value, label, total, editable }) =>
    Boolean(total) ? (
      <>
        <Box flex={1} />
        <Divider sx={styles?.divider} />
        <Typography textAlign="end" variant="h6" color="primary">
          {(value || 0)?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </>
    ) : (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>{label}</Typography>
        <Typography fontWeight="bold" alignItems="center" display="flex">
          {Boolean(editable) && (
            <IconButton
              sx={styles?.iconButton}
              onClick={() =>
                openModal(
                  <EditModal
                    title={label}
                    onSubmit={({ valor }) => {
                      closeModal();
                      setValue(
                        editable,
                        (Number(watch(editable)) || 0) + (Number(valor) || 0)
                      );
                    }}
                  />
                )
              }
            >
              <Add sx={styles?.icon} />
            </IconButton>
          )}
          {(value || 0)?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Box>
    );

  return (
    <Container>
      <Header titulo="Apuração de Tributos" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <Dropdown
                  name="filial_id"
                  control={control}
                  label="Filial"
                  options={drops?.Filial}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Dropdown
                  name="tributo_id"
                  control={control}
                  label="Tributo"
                  options={drops?.Tributo}
                  onValueChange={() => setValue('uf_id', null)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  name="uf_id"
                  control={control}
                  label="UF"
                  options={drops?.UF}
                  disabled={watch('tributo_id') !== 2}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputMask
                  name="dt"
                  control={control}
                  label="Mês / Ano"
                  mask="99/9999"
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  loading={getLoading}
                  onClick={handleSubmit(loadData)}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {getLoading ? (
          <Loader />
        ) : Boolean(apuracoes?.length) ? (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} display="flex">
                  <Card title="Débito do Imposto" style={styles?.card}>
                    {debitos?.map(({ key, ...props }) => (
                      <Line {...props} key={key} />
                    ))}
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} display="flex">
                  <Card title="Crédito do Imposto" style={styles?.card}>
                    {creditos?.map(({ key, ...props }) => (
                      <Line {...props} key={key} />
                    ))}
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} display="flex">
                  <Card title="Apuração do Saldo" style={styles?.card}>
                    {balanco?.map(({ key, ...props }) => (
                      <Line {...props} key={key} />
                    ))}
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card title="Responsável / Recolhimento">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Input name="resp_nome" control={control} label="Nome" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputMask
                      name="resp_docto"
                      control={control}
                      label="Documento"
                      mask="999.999.999-99"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Input
                      name="resp_qualificacao"
                      control={control}
                      label="Qualificação"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      name="codrec"
                      control={control}
                      label="Código do Recolhimento"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      name="codrecinter"
                      control={control}
                      label="Código do Recolhimento Interestadual"
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Button
                      variant="contained"
                      loading={postLoading}
                      onClick={handleSubmit(onsubmit)}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} textAlign="center">
            <Typography variant="caption">
              Nenhum registro encontrado
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Apuração;
